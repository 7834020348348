import React from "react";

const Falcon = () => {
  return (
    <svg
      className="falcon-icon"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.7513 21.5C11.6563 21.5003 11.5622 21.4825 11.4738 21.4475C11.351 21.3986 11.2432 21.318 11.1616 21.2139C11.0799 21.1098 11.0272 20.986 11.0088 20.855L10.2588 15.605L11.7588 15.395L12.3213 19.3475L14.7513 17.39V11.75C14.7508 11.6513 14.7697 11.5535 14.807 11.4621C14.8443 11.3707 14.8993 11.2876 14.9688 11.2175L18.0213 8.16505C18.6503 7.54034 19.1492 6.7971 19.489 5.97833C19.8289 5.15957 20.003 4.28154 20.0013 3.39505V2.00005H18.6063C17.7198 1.99836 16.8418 2.17249 16.023 2.51235C15.2043 2.85222 14.461 3.35107 13.8363 3.98005L10.7838 7.03255C10.7138 7.10206 10.6306 7.15705 10.5393 7.19438C10.4479 7.2317 10.35 7.25062 10.2513 7.25005H4.61133L2.65383 9.69505L6.60633 10.2575L6.39633 11.7575L1.14633 11.0075C1.01534 10.9892 0.891534 10.9365 0.787477 10.8548C0.68342 10.7732 0.602813 10.6654 0.55383 10.5425C0.504241 10.4188 0.488524 10.2841 0.508298 10.1523C0.528072 10.0205 0.582621 9.89628 0.666331 9.79255L3.66633 6.04255C3.73542 5.95278 3.82395 5.87983 3.92527 5.82917C4.02658 5.77851 4.13806 5.75146 4.25133 5.75005H9.94383L12.7713 2.91505C13.5357 2.14638 14.4451 1.5371 15.4467 1.12254C16.4483 0.707982 17.5223 0.496395 18.6063 0.500046H20.0013C20.3992 0.500046 20.7807 0.658082 21.062 0.939386C21.3433 1.22069 21.5013 1.60222 21.5013 2.00005V3.39505C21.505 4.47907 21.2934 5.55304 20.8788 6.55467C20.4643 7.5563 19.855 8.46567 19.0863 9.23005L16.2513 12.0575V17.75C16.2508 17.8627 16.2249 17.9738 16.1756 18.0751C16.1262 18.1763 16.0547 18.2652 15.9663 18.335L12.2163 21.335C12.0844 21.4409 11.9205 21.4991 11.7513 21.5Z" />
    </svg>
  );
};

export default Falcon;
